import React from "react"
import styled from "styled-components"

import AppLayout from "@components/layout/App"
import LevelingCriteriaBox from "@components/elements/LevelingCriteriaBox"
import CAREER_LEVEL_DATA from "@content/career-levels.json"

import { getCareerLevelUrl } from "@helpers/url"

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  padding: 0 8px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const TitleContainer = styled.div`
  padding: 2rem 0;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 1rem 0;
  }
`

const SubHeader = styled.p`
  color: #666;
  font-size: 16px;
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  margin: 0 4px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const Page = props => {
  return (
    <AppLayout>
      <PageContainer>
        <TitleContainer>
          <h1>Career Bands</h1>
          <SubHeader>Click on any career band to view criteria</SubHeader>
        </TitleContainer>

        <FlexDiv>
          {CAREER_LEVEL_DATA.map((cl, index) => {
            return (
              <LevelingCriteriaBox
                bgColor={cl.bgColor}
                text={cl.name}
                to={getCareerLevelUrl(cl.slug)}
                key={`career-level-data-${index}`}
              />
            )
          })}
        </FlexDiv>
      </PageContainer>
    </AppLayout>
  )
}

export default Page
