import React from "react"
import styled from "styled-components"

import UnstyledLink from "@components/elements/UnstyledLink"

const Box = styled.div`
  width: 298px;
  height: 60px;
  line-height: 60px;
  text-align: center;

  font-weight: 700;
  font-size: 16px;

  color: white;
  background: ${props => props.bgColor || "#D6D7D7"};
  margin: 0 4px;

  @media (max-width: 1023px) {
    // flex: 0 0 100%;
    // width: 50px;
    // height: 50px;
    // line-height: 50px;

    display: ${props => (props.hide ? "none" : "block")};
  }
`

const DesktopContent = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const MobileContent = styled.div`
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
`

const LevelingCriteriaBox = ({ text, to, bgColor }) => {
  return (
    <div style={{ margin: "10px" }}>
      <DesktopContent>
        <UnstyledLink to={to}>
          <Box bgColor={bgColor}>{text}</Box>
        </UnstyledLink>
      </DesktopContent>
      <MobileContent>
        <UnstyledLink to={to}>
          <Box bgColor={bgColor}>{text}</Box>
        </UnstyledLink>
      </MobileContent>
    </div>
  )
}

export default LevelingCriteriaBox
